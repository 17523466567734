import {BuddyButton} from "../components/input/button";
import {useTranslation} from "react-i18next";

export const MissionPage = () => {
    const { t } = useTranslation();

    const scrollToNewsletter = () => {
        const section = document.getElementById('newsletter');
        if (section) {
            section.scrollIntoView({ behavior: 'smooth' });
        }
    };

    return (
        <div className="flex flex-row pb-10 gap-4">
            <div
                className="flex flex-col lg:flex-row w-full justify-center gap-14 lg:gap-40 xl:gap-60 2xl:gap-80 pt-24 pb-24 px-6 lg:px-48 items-center transition-all ease-in-out duration-500">
                <div className="flex flex-col text-center lg:text-left gap-6 items-center lg:items-start">
                    {/* Title for larger screens */}
                    <h1 className="hidden sm:block text-4xl font-bold text-[#2F2F2F]">
                        {t('missionSection.title')}
                    </h1>

                    {/* Title for smaller screens */}
                    <h1 className="block sm:hidden text-3xl font-bold text-[#2F2F2F]">
                        {t('missionSection.title')}
                    </h1>

                    {/* Description for larger screens (split after 8 words) */}
                    <p className="hidden sm:block text-xl font-semibold text-[#888888]">
                        {t('missionSection.description').split(' ').slice(0, 8).join(' ')}<br />
                        {t('missionSection.description').split(' ').slice(8).join(' ')}
                    </p>

                    {/* Description for smaller screens (split after 4 words) */}
                    <p className="block sm:hidden text-xl font-semibold text-[#888888]">
                        {t('missionSection.description').split(' ').slice(0, 5).join(' ')}<br />
                        {t('missionSection.description').split(' ').slice(5, 8).join(' ')}<br/>
                        {t('missionSection.description').split(' ').slice(8, 12).join(' ')}
                    </p>
                </div>

                <div className="mb-5">
                    <BuddyButton text={t('missionSection.ctaButton')} isDisabled={false} onClick={scrollToNewsletter}/>
                </div>
            </div>
        </div>
    );
};