import imgMatch from '../assets/images/match.png';
import {useTranslation} from "react-i18next";

export const MatchSection = () => {
    const { t } = useTranslation();

    return <div className="flex bg-[#FFF]">
        <div className="flex flex-col-reverse lg:flex-row w-full justify-center gap-20 xl:gap-40 2xl:gap-60 pt-24 pb-24 px-6 lg:px-48 items-center transition-all ease-in-out duration-500">
            <div className="flex gap-3">
                <img src={imgMatch} alt="Match-Preview" className="max-w-none h-auto"width="315" height="369"></img>
            </div>
            <div className="flex flex-col text-center lg:text-left gap-6 items-center lg:items-start">
                <div
                    className="flex w-fit h-fit text-base lg:text-lg text-[#EF144C] font-semibold bg-[#F2D6DD] px-10 py-2 rounded-full">
                    {t('matchSection.stepLabel')}
                </div>
                {/* Heading for smaller screens */}
                <h1 className="block text-3xl lg:text-4xl font-bold text-[#2F2F2F]">
                    {t('matchSection.title').split(' ').slice(0, 3).join(' ')}<br />
                    {t('matchSection.title').split(' ').slice(3).join(' ')}
                </h1>


                {/* Description for larger screens */}
                <p className="hidden sm:block text-xl font-semibold text-[#888888]">
                    {t('matchSection.description').split(' ').slice(0, 7).join(' ')}<br />
                    {t('matchSection.description').split(' ').slice(7, 16).join(' ')}<br />
                    {t('matchSection.description').split(' ').slice(16).join(' ')}
                </p>

                {/* Description for smaller screens */}
                <p className="block sm:hidden text-xl font-semibold text-[#888888]">
                    {t('matchSection.description').split(' ').slice(0, 4).join(' ')}<br />
                    {t('matchSection.description').split(' ').slice(4, 9).join(' ')}<br />
                    {t('matchSection.description').split(' ').slice(9, 15).join(' ')}<br />
                    {t('matchSection.description').split(' ').slice(15).join(' ')}
                </p>
            </div>
        </div>
    </div>
}