import imgInterests from '../assets/images/interest.png';
import {useTranslation} from "react-i18next";

export const InterestSection = () => {
    const { t } = useTranslation();

    return <div id="interest" className="flex bg-[#F3F3F3] pl-28 pr-28">
        <div className="flex flex-col lg:flex-row w-full justify-center gap-14 xl:gap-40 2xl:gap-60 pt-24 pb-24 px-6 lg:px-48 items-center transition-all ease-in-out duration-500">
            <div className="flex flex-col text-center lg:text-left gap-6 items-center lg:items-start">
                <div className="flex w-fit h-fit text-base lg:text-lg text-[#EF144C] font-semibold bg-[#F2D6DD] px-10 py-2 rounded-full">
                    {t('interestsSection.stepLabel')}
                </div>
                {/* Heading for larger screens */}
                <h1 className="hidden sm:block text-4xl font-bold text-[#2F2F2F]">
                    {t('interestsSection.title')}
                </h1>
                {/* Heading for smaller screens */}
                <h1 className="block sm:hidden text-3xl lg:text-4xl font-bold text-[#2F2F2F]">
                    {t('interestsSection.title').split(' ').slice(0, 2).join(' ')}<br />
                    {t('interestsSection.title').split(' ').slice(2).join(' ')}
                </h1>

                {/* Description for larger screens */}
                <p className="hidden sm:block text-xl font-semibold text-[#888888]">
                    {t('interestsSection.description').split(' ').slice(0, 8).join(' ')}<br />
                    {t('interestsSection.description').split(' ').slice(8, 15).join(' ')}<br />
                    {t('interestsSection.description').split(' ').slice(15).join(' ')}
                </p>

                {/* Description for smaller screens */}
                <p className="block sm:hidden text-xl font-semibold text-[#888888]">
                    {t('interestsSection.description').split(' ').slice(0, 5).join(' ')}<br />
                    {t('interestsSection.description').split(' ').slice(5, 9).join(' ')}<br />
                    {t('interestsSection.description').split(' ').slice(9, 14).join(' ')}<br />
                    {t('interestsSection.description').split(' ').slice(14).join(' ')}
                </p>
            </div>
            <div className="flex gap-3">
                <img src={imgInterests} alt="Interest-Preview" className="max-w-none h-auto" width="315" height="369"></img>
            </div>
        </div>
    </div>
}