import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyA2fgmje0_2skUQEw4pzJjRghtEyn7vVZc",
  authDomain: "buddyappcore.firebaseapp.com",
  projectId: "buddyappcore",
  storageBucket: "buddyappcore.appspot.com",
  messagingSenderId: "508740121924",
  appId: "1:508740121924:web:0e8129d4ec1e6c0a3a5311",
  measurementId: "G-RJWZJ6Q812"
};

const app = initializeApp(firebaseConfig);

export const db = getFirestore(app);

export const analytics = getAnalytics(app);
