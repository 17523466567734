import './App.css';
import {HomePage} from "./pages/HomePage";
import {MissionPage} from "./pages/MissionPage";
import {InterestSection} from "./pages/InterestSection";
import {MatchSection} from "./pages/MatchSection";
import {ChatSection} from "./pages/ChatSection";
import {SignInPage} from "./pages/SignInPage";
import {Footer} from "./pages/Footer";

function App() {
  return (
      <div className="select-none font-sans text-nowrap">
          <HomePage/>
          <MissionPage/>
          <InterestSection/>
          <MatchSection/>
          <ChatSection/>
          <SignInPage/>
          <Footer/>
      </div>
  );
}

export default App;
