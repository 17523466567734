import React, { useEffect, useState } from 'react';
import { MailIcon } from "../../assets/componentImages/MailIcon";
import { CheckIcon } from "../../assets/componentImages/CheckIcon";

const EmailInput = ({ value, onChange, setIsValid }) => {
  const [showCheckIcon, setShowCheckIcon] = useState(false);

  useEffect(() => {
    const handler = setTimeout(() => {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      const valid = emailRegex.test(value);
      setShowCheckIcon(valid);
      setIsValid(valid);
    }, 300);

    return () => {
      clearTimeout(handler);
    };
  }, [setIsValid, value]);

  return (
    <div className="flex flex-row items-center border-[1.7px] border-[#E6E6E6] rounded-lg px-4 py-3.5 w-full max-w-md">
      <MailIcon />
      <div className="ml-4 w-0.5 rounded-full bg-[#E5E5E5] h-6" />
      <input
        type="email"
        value={value}
        onChange={onChange}
        placeholder="maxmuster@gmail.com"
        className="flex-1 border-none outline-none ml-3 text-[#3E3E3E] font-medium"
      />
      {showCheckIcon && <CheckIcon />}
    </div>
  );
};

export default EmailInput;
