import {BuddyButton} from "../components/input/button";
import {BuddyIcon} from "../assets/componentImages/BuddyIcon";
import {NavButton} from "../components/input/NavButton";
import {ScrollIcon} from "../assets/componentImages/ScrollIcon";
import {useTranslation} from "react-i18next";


export const HomePage = () => {
    const { t } = useTranslation();

    const scrollToNewsletter = () => {
        const section = document.getElementById('newsletter');
        if (section) {
            section.scrollIntoView({ behavior: 'smooth' });
        }
    };

    const scrollToInterests = () => {
        const section = document.getElementById('interest');
        if (section) {
            section.scrollIntoView({ behavior: 'smooth' });
        }
    };

    return (
        <div className="flex flex-col w-full pl-6 lg:pl-20 pr-6 lg:pr-20 pt-12 lg:pt-20 sm:p-20">
            <div className="relative gap-5 bg-[url('./assets/images/home_screen_image_LQ.png')] bg-center bg-cover bg-no-repeat w-full min-h-[70vh] lg:min-h-[50vh] items-center justify-center rounded-[2rem] lg:rounded-[3rem] transition-all ease-in-out duration-500">
                <div className="absolute inset-0 flex gap-8 flex-col items-center justify-center">
                    <div className="absolute left-8 lg:left-16 top-12 lg:top-14">
                        <BuddyIcon />
                    </div>
                    <div className="absolute top-12 lg:top-14 right-8 lg:right-16">
                        <NavButton text="Early Member" onClick={scrollToNewsletter} />
                    </div>
    
                    <h1 className="hidden lg:block text-5xl xl:text-6xl font-bold text-white text-shadow-custom transition-transform duration-800 ease-in-out transform">
                        {t('startSection.title')}
                    </h1>
                    <h1 className="block lg:hidden text-4xl sm:text-5xl md:text-5xl mb-3 font-bold text-white text-shadow-custom text-center mt-10 transition-transform duration-800 ease-in-out transform">
                        {t('startSection.title').split(' ').slice(0, 2).join(' ')}<br />
                        {t('startSection.title').split(' ').slice(2).join(' ')}
                    </h1>
                    <BuddyButton text={t('startSection.subheading')} isDisabled={false} onClick={scrollToInterests} />
                </div>
            </div>
            <div className="flex sm:hidden w-full justify-center items-center pt-8 gap-4">
                <p className="text-xl font-semibold text-[#CCC]">
                    {t('scrollSection.scrollText')}
                </p>
                <ScrollIcon />
            </div>
        </div>
    );
    
};
