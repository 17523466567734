import ChatPreviewImage from '../assets/images/chat.png';
import {useTranslation} from "react-i18next";

export const ChatSection = () => {
    const { t } = useTranslation();

    return (
        <div className="flex bg-[#F3F3F3]">
            <div className="flex flex-col lg:flex-row w-full justify-center gap-20 xl:gap-40 2xl:gap-56 pt-24 pb-24 px-6 lg:px-48 items-center transition-all ease-in-out duration-500">
                <div className="flex flex-col text-center lg:text-left gap-6 items-center lg:items-start">
                    <div
                        className="flex w-fit h-fit text-base lg:text-lg text-[#EF144C] font-semibold bg-[#F2D6DD] px-10 py-2 rounded-full self-center lg:self-start">
                        {t('chatSection.stepLabel')}
                    </div>

                    {/* Headline - large screen size */}
                    <h1 className="hidden sm:block text-4xl font-bold text-[#2F2F2F]">
                        {t('chatSection.title').split(' ').slice(0, 2).join(' ')}<br/>
                        {t('chatSection.title').split(' ').slice(2).join(' ')}
                    </h1>

                    <h1 className="block sm:hidden text-3xl lg:text-4xl font-bold text-[#2F2F2F]">
                        {t('chatSection.title').split(' ').slice(0, 3).join(' ')}<br/>
                        {t('chatSection.title').split(' ').slice(3).join(' ')}
                    </h1>

                    <p className="hidden sm:block text-xl font-semibold text-[#888888]">
                        {t('chatSection.description').split(' ').slice(0, 10).join(' ')}<br/>
                        {t('chatSection.description').split(' ').slice(10, 18).join(' ')}<br/>
                        {t('chatSection.description').split(' ').slice(18).join(' ')}
                    </p>

                    <p className="block sm:hidden text-xl font-semibold text-[#888888]">
                        {t('chatSection.description').split(' ').slice(0, 8).join(' ')}<br/>
                        {t('chatSection.description').split(' ').slice(8, 13).join(' ')}<br/>
                        {t('chatSection.description').split(' ').slice(13, 17).join(' ')}<br/>
                        {t('chatSection.description').split(' ').slice(17, 21).join(' ')}<br/>
                        {t('chatSection.description').split(' ').slice(21).join(' ')}
                    </p>
                </div>
                <div className="flex gap-3">
                    <img src={ChatPreviewImage} alt="Chat-Preview" className="max-w-none h-auto" width="295"
                         height="343"/>
                </div>
            </div>
        </div>
    );
};
