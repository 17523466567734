import EmailInput from "../components/input/EmailInput";
import React, { useState } from "react";
import { collection, addDoc, serverTimestamp } from "firebase/firestore";
import { db, analytics } from "../services/firebase";
import { logEvent } from "firebase/analytics";
import {useTranslation} from "react-i18next";

export const SignInPage = () => {
    const { t } = useTranslation();
    const [email, setEmail] = useState("");
    const [isValid, setIsValid] = useState(false);
    const [successMessage, setSuccessMessage] = useState("");

    const addEmailToFirestore = async () => {
        if (!isValid) {
            alert(t('firebase.alerts.invalidEmail'));
            return;
        }

        try {
            await addDoc(collection(db, "newsletter"), {
                email: email,
                timestamp: serverTimestamp(),
            });

            logEvent(analytics, 'newsletter_signup', {
                email: email
            });

            setEmail("");
            setSuccessMessage(t('firebase.alerts.success'));

            setTimeout(() => {
                setSuccessMessage("");
            }, 5000);
        } catch (error) {
            console.error(t('firebase.alerts.error'), error);
        }
    };

    return (
        <div id="newsletter" className="flex flex-col gap-8 pt-36 pb-40 items-center justify-center py-24">
            <h1 className="text-4xl text-[#343434] font-bold text-center">
                {t('releaseSection.title').split(' ').slice(0, 2).join(' ')}<br/>
                {t('releaseSection.title').split(' ').slice(2, 3).join(' ')}<br/>
                {t('releaseSection.title').split(' ').slice(3).join(' ')}
            </h1>

            <div className="mt-2">
                <EmailInput value={email} onChange={(e) => setEmail(e.target.value)} setIsValid={setIsValid}/>
            </div>

            <button
                onClick={addEmailToFirestore}
                className="flex text-sm text-white font-semibold bg-[#343434] hover:bg-[#414141] px-10 py-3.5 rounded-xl"
            >
                <p className="text-sm">{t('releaseSection.ctaButton')}</p>
            </button>

            {successMessage && (
                <p className="text-green-500 font-semibold mt-4 text-center">
                    {successMessage}
                </p>
            )}
        </div>
    );
};
